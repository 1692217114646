import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
} from '@angular/material';

import { UIComponentsModule } from '../ui-components/ui-components.module';
import { ConfirmDialogComponent } from './confirm/confirm-dialog.component';
import { ConfirmService } from './confirm/confirm.service';
import { ConfirmDeleteService } from './confirm-delete/confirm-delete.service';
import { ConfirmMotivoDeleteService } from './confirm-motivo-delete/confirm-motivo-delete.service';
import { ConfirmDeleteDialogComponent } from './confirm-delete/confirm-delete-dialog.component';
import { ConfirmMotivoDeleteDialogComponent } from './confirm-motivo-delete/confirm-motivo-delete-dialog.component';
import { ErrorService } from './error/error.service';
import { ErrorDialogComponent } from './error/error-dialog.component';
import { ContratacaoMensagemService } from './contratacao-mensagem/contratacao-mensagem.service';
import { ContratacaoMensagemDialogComponent } from './contratacao-mensagem/contratacao-mensagem-dialog.component';
import { ContratacaoQuantidadeService } from './contratacao-quantidade/contratacao-quantidade.service';
import { ContratacaoQuantidadeApiService } from './contratacao-quantidade/store/contratacao-quantidade-api.service';
import { ContratacaoQuantidadeDialogComponent } from './contratacao-quantidade/contratacao-quantidade-dialog.component';
import { ContratacaoQuantidadeRequisitanteService } from './contratacao-quantidade-requisitante/contratacao-quantidade-requisitante.service';
import { ContratacaoQuantidadeFilialService } from './contratacao-quantidade-filial/contratacao-quantidade-filial.service';
import { ContratacaoQuantidadeRequisitanteApiService } from './contratacao-quantidade-requisitante/store/contratacao-quantidade-requisitante-api.service';
import { ContratacaoQuantidadeFilialApiService } from './contratacao-quantidade-filial/store/contratacao-quantidade-filial-api.service';
import { ContratacaoQuantidadeRequisitanteDialogComponent } from './contratacao-quantidade-requisitante/contratacao-quantidade-requisitante-dialog.component';
import { ContratacaoQuantidadeFilialDialogComponent } from './contratacao-quantidade-filial/contratacao-quantidade-filial-dialog.component';
import { ContratacaoConclusaoService } from './contratacao-conclusao/contratacao-conclusao.service';
import { ContratacaoRelatoriosService } from './contratacao-relatorios/contratacao-relatorios.service';
import { ContratacaoRelatoriosApiService } from './contratacao-relatorios/store/contratacao-relatorios-api.service';
import { ContratacaoRelatoriosDialogComponent } from './contratacao-relatorios/contratacao-relatorios-dialog.component';
import { ContratacaoTreinamentosService } from './contratacao-treinamentos/contratacao-treinamentos.service';
import { ContratacaoTreinamentosApiService } from './contratacao-treinamentos/store/contratacao-treinamentos-api.service';
import { ContratacaoTreinamentosDialogComponent } from './contratacao-treinamentos/contratacao-treinamentos-dialog.component';
import { ContratacaoIntegracoesService } from './contratacao-integracoes/contratacao-integracoes.service';
import { ContratacaoIntegracoesApiService } from './contratacao-integracoes/store/contratacao-integracoes-api.service';
import { ContratacaoIntegracoesDialogComponent } from './contratacao-integracoes/contratacao-integracoes-dialog.component';
import { ContratacaoPowerBIService } from './contratacao-powerbi/contratacao-powerbi.service';
import { ContratacaoPowerBIApiService } from './contratacao-powerbi/store/contratacao-powerbi-api.service';
import { ContratacaoPowerBIDialogComponent } from './contratacao-powerbi/contratacao-powerbi-dialog.component';
import { ContratacaoConclusaoDialogComponent } from './contratacao-conclusao/contratacao-conclusao-dialog.component';
import { MessageInformacaoService } from './message-informacao/message-informacao.service';
import { MessageInformacaoDialogComponent } from './message-informacao/message-informacao-dialog.component';
import { DataSharedServicesModule } from './data/data-shared-services.module';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { MessageDialogService } from './message-dialog/message-dialog.service';
import { MessageService } from './message/message.service';

@NgModule({
    imports: [
        UIComponentsModule,
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSnackBarModule,

        DataSharedServicesModule,
    ],
    exports: [],
    declarations: [
        MessageDialogComponent,
        ConfirmDialogComponent,
        ConfirmDeleteDialogComponent,
        ConfirmMotivoDeleteDialogComponent,
        ErrorDialogComponent,
        ContratacaoMensagemDialogComponent,
        ContratacaoQuantidadeDialogComponent,
        ContratacaoQuantidadeRequisitanteDialogComponent,
        ContratacaoQuantidadeFilialDialogComponent,
        ContratacaoRelatoriosDialogComponent,
        ContratacaoTreinamentosDialogComponent,
        ContratacaoIntegracoesDialogComponent,
        ContratacaoPowerBIDialogComponent,
        ContratacaoConclusaoDialogComponent,
        MessageInformacaoDialogComponent,
    ],
    entryComponents: [
        MessageDialogComponent,
        ConfirmDialogComponent,
        ConfirmDeleteDialogComponent,
        ConfirmMotivoDeleteDialogComponent,
        ErrorDialogComponent,
        ContratacaoMensagemDialogComponent,
        ContratacaoQuantidadeDialogComponent,
        ContratacaoQuantidadeRequisitanteDialogComponent,
        ContratacaoQuantidadeFilialDialogComponent,
        ContratacaoRelatoriosDialogComponent,
        ContratacaoTreinamentosDialogComponent,
        ContratacaoIntegracoesDialogComponent,
        ContratacaoPowerBIDialogComponent,
        ContratacaoConclusaoDialogComponent,
        MessageInformacaoDialogComponent,
    ],
    providers: [
        ConfirmService,
        ConfirmDeleteService,
        ConfirmMotivoDeleteService,
        ErrorService,
        ContratacaoMensagemService,
        ContratacaoQuantidadeService,
        ContratacaoQuantidadeApiService,
        ContratacaoQuantidadeRequisitanteService,
        ContratacaoQuantidadeRequisitanteApiService,
        ContratacaoQuantidadeFilialService,
        ContratacaoQuantidadeFilialApiService,
        ContratacaoRelatoriosService,
        ContratacaoRelatoriosApiService,
        ContratacaoTreinamentosService,
        ContratacaoTreinamentosApiService,
        ContratacaoIntegracoesService,
        ContratacaoIntegracoesApiService,
        ContratacaoPowerBIService,
        ContratacaoPowerBIApiService,
        ContratacaoConclusaoService,
        MessageInformacaoService,
        MessageDialogService,
        MessageService,
    ],
})
export class SharedServicesModule {}
