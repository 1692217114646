import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../../../app.settings';

@Injectable()
export class ContratacaoRelatoriosApiService {
    private readonly URL_API = `${AppSettings.API_ENDPOINT_SISTEMA}/sistema`;

    constructor(private _http: HttpClient) {}

    inserirUsuarios(
        quantidade: number,
        valorUnitario: number
    ): Observable<any> {
        const obj = {
            quantidade,
            valorUnitario,
        };

        return this._http.post<any>(
            `${this.URL_API}/cliente-contrato/adicionar-quantidade-usuarios`,
            obj
        );
    }
}
