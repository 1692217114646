import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ContratacaoPowerBIApiService } from './store/contratacao-powerbi-api.service';

@Component({
    selector: 'app-contratacao-powerbi-dialog',
    templateUrl: './contratacao-powerbi-dialog.component.html',
    styleUrls: ['./contratacao-powerbi-dialog.component.scss'],
})
export class ContratacaoPowerBIDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoPowerBIDialogComponent>,
        private apiService: ContratacaoPowerBIApiService
    ) {}

    ngOnInit() {}

    confirmar(): void {
        this.dialogRef.close(true);
    }
}
